import WasmController from "react-lib/frameworks/WasmController";

// APIs
// IME
import ImagingQueueList from "issara-sdk/apis/ImagingQueueList_apps_IME";
// CORE
import EncounterDetail from "issara-sdk/apis/EncounterDetail_core";

// Interface
import { State as MainState } from "../../../../../HIS/MainHISInterface";
import {
  GetClinicalFindingList,
  GetOrgan,
  HandleModXrayDetail,
  ModXrayDetailType,
  ReportActionStatusType,
  CreateUpdateImagingResult,
  GetAuditLogList,
  GetOrderItemImageList,
  PacsGalleryDetailType,
  ActionStatusType,
  REPORT_ACTION_STATUS,
  ACTION_STATUS,
  ImagingQueueSerializer,
  ImagingOrderItemActionLogSerializer,
  OrderItemStatusType,
  BillingStatusType,
  ORDER_ITEM_STATUS_LABEL,
  UpdateOrderItemAction,
  ORDER_STATUS,
  BILLING_STATUS,
  OpenPacsViewer,
} from "./ImagingHandler";
import { SetErrorMessage, mapOptions } from "../../common/CommonInterface";

import moment from "moment";

// Utils
import { beToAd, formatDate } from "react-lib/utils/dateUtils";

export type State = Partial<{
  // CommonInterface
  imagingOrderEditId: number | null;
  imagingResultEditData: (ImagingQueueSerializer & { readOnly?: boolean }) | null;
  selectedRecordViewIndex: number;
  masterOptions: Record<string, any[]>;

  // sequence
  ImagingWorkListSequence: Partial<{
    sequenceIndex: "Start" | "Action" | null;
    orderQueue: Partial<{
      items: ImagingQueueSerializer[];
      total: number;
      activePage: number;
    }>;
    filter: Partial<FilterType>;
    userId: number;
    auditLogList: ImagingOrderItemActionLogSerializer[];
    pacsGalleryDetail: PacsGalleryDetailType | null;
  }> | null;
}>;

type Picked = Partial<
  Pick<
    MainState,
    | "buttonLoadCheck"
    | "errorMessage"
    | "django"
    | "searchedItemListWithKey"
    | "selectedEncounter"
    | "userTokenize"
  >
>;

export type MasterOptionsType = Record<(typeof Masters)[number][0], OptionType[]>;

type UpdateOrderType = {
  id: number;
  data?: Partial<ImagingQueueSerializer>;
  key?: string;
  value?: string | boolean;
};

type FilterType = {
  division: number | null;
  xray_group: number;
  radiologist: number | null;
  orderStatus: OrderItemStatusType;
  billingStatus: BillingStatusType | "";
  encounterType: "IPD" | "OPD" | "";
  patient: number | null;
  orderNo: string | null;
  startDate: string;
  endDate: string;
};

export type OrderItemUpdateStatus = {
  id: number;
  // ActionStatusType
  executed_by?: number;
  executed_datetime?: string;
  radiologist?: number;
  radiologist_name?: string;
  action_note?: string;
  ready_to_view?: boolean;
  // ReportActionStatusType
  username?: string;
  password?: string;
  // Pacs
  image_url?: string;
  image_id?: number | null;
};

type OptionType = {
  key: number | string;
  value: number | string;
  text: string;
};

// Sequence
type SeqState = {
  sequence: "ImagingWorkList";
  restart?: boolean;
  clear?: boolean;
  card?: string;
  doctorDetail?: { id: number; name_code: string };
};

// Handle Action
type ActionType =
  // Search
  | { action: "SEARCH"; card: string; activePage: number }
  // Action
  | {
      action: "MOD_XRAY";
      data?: ModXrayDetailType;
      type: "OPEN" | "CLOSE";
    }
  | {
      action: "GET_AUDIT_LOG";
      orderItemId: number;
    }
  | {
      action: "OPEN_PACS";
      card: string;
      data: ImagingQueueSerializer;
      index: number;
    }
  | {
      action: "GO_TO_ENCOUNTER";
      item: ImagingQueueSerializer & { readOnly?: boolean };
      menu: "Imaging Order" | "Imaging Result";
      forward?: () => any;
    }
  // Method
  | {
      action: "UPDATE_STATUS";
      card: string;
      errorKey?: string;
      btnAction?: string;
      orderItems: OrderItemUpdateStatus[];
      actionType: ReportActionStatusType | ActionStatusType;
      index?: number;
      onSuccess?: Function;
    };

type SeqAct = ActionType & SeqState;
type SeqType<K> = K extends { action: string } ? Extract<SeqAct, K> : SeqState;

export type RunSequence = <K extends keyof SeqAct>(params: SeqType<Pick<SeqAct, K>>) => any;

type CustomExtract<T, U> = T extends object ? (U extends Partial<T> ? T : never) : never;

type Params<A extends ActionType["action"]> = CustomExtract<ActionType, { action: A }>;

export const StateInitial: State = {
  // sequence
  ImagingWorkListSequence: {
    sequenceIndex: null,
  },
};

export type Event = { message: "RunSequence"; params: {} };

export type Data = {
  division?: number;
  device?: number;
  userProfile?: Record<string, any>;
};

export const DataInitial = {};

const Masters = [
  ["cancelImagingOrder", {}],
  ["eligibilityType", {}],
  ["division", {}],
  ["imagingTestGroup", {}],
] as const;

export const ORDER_STATUS_COLORS = {
  ORDERED: "#ca0000",
  [ORDER_STATUS.REQUESTED]: "#ca0000",
  [ORDER_STATUS.REGISTERED]: "#1f7ec5",
  [ORDER_STATUS.EXECUTED]: "#f7ac08",
  [ORDER_STATUS.REPORTED]: "#9b51e0",
  [ORDER_STATUS.APPROVED]: "#1b9d2c",
  [ORDER_STATUS.CANCELED]: "#000000",
  "": "",
};

export const BILLING_STATUS_COLORS = {
  BILLED: "#fb7c28",
  [BILLING_STATUS.PENDING]: "#fb7c28",
  [BILLING_STATUS.PAID]: "#1b9d2c",
  [BILLING_STATUS.PARTIAL]: "#898383",
};

export const ORDER_PAYMENT_STATUS = {
  1: "PENDING",
  2: "READY",
  3: "PAID",
  4: "PARTIAL_PAID",
};

export const ORDER_STATUS_OPTIONS = mapOptions(
  Object.entries(ORDER_ITEM_STATUS_LABEL).map(([key, value]) => ({
    id: key,
    name: value,
  }))
);

export const BILLING_STATUS_OPTIONS = mapOptions([...new Set(Object.values(BILLING_STATUS))]);

export const ENCOUNTER_TYPE_OPTIONS = mapOptions(["IPD", "OPD"]);

export const RADIOLOGIST_SEARCH_ID = "Doctor_IWL";

export const WORK_LIST_LIMIT = 20;

type Handler<P = any, R = any> = (
  controller: WasmController<State & Picked, Event, Data>,
  params: P
) => R;

/* ------------------------------------------------------ */

/*                          START                         */

/* ------------------------------------------------------ */
export const GetMaster: Handler<SeqState> = async (controller, params) => {
  const state = controller.getState();

  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: Masters,
    },
  } as any);

  GetClinicalFindingList(controller, {});

  GetOrgan(controller, {});

  const doctorId = params.doctorDetail?.id || null;

  controller.setState(
    {
      ImagingWorkListSequence: {
        ...state.ImagingWorkListSequence,
        sequenceIndex: "Action",
        filter: {
          startDate: formatDate(moment()),
          endDate: formatDate(moment()),
          radiologist: params.doctorDetail?.id || null,
        },
        userId: state.django?.user?.id,
      },
    },
    async () => {
      if (doctorId) {
        await controller.setState({
          searchedItemListWithKey: {
            ...state.searchedItemListWithKey,
            [RADIOLOGIST_SEARCH_ID]: [params.doctorDetail],
          },
        });
      }

      Action(controller, {
        action: "SEARCH",
        card: params.card || "",
        activePage: 1,
      });
    }
  );
};

/* ------------------------------------------------------ */

/*                      Handle Action                     */

/* ------------------------------------------------------ */
export const Action: Handler<ActionType> = async (controller, params) => {
  if (params.action === "SEARCH") {
    HandleSearch(controller, params);
  } else if (params.action === "MOD_XRAY") {
    HandleModDetail(controller, params);
  } else if (params.action === "UPDATE_STATUS") {
    HandleUpdateStatus(controller, params);
  } else if (params.action === "GET_AUDIT_LOG") {
    HandleGetAuditLog(controller, params);
  } else if (params.action === "OPEN_PACS") {
    HandleOpenPacs(controller, params);
  } else if (params.action === "GO_TO_ENCOUNTER") {
    handleGotoEncounter(controller, params);
  }
};

const HandleSearch: Handler<Params<"SEARCH">> = async (controller, params) => {
  let state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  const [result] = await GetImagingQueueList(controller, { ...params });

  state = controller.getState();

  controller.setState({
    ImagingWorkListSequence: {
      ...state.ImagingWorkListSequence,
      orderQueue: {
        items: result?.items || [],
        total: result?.total || 0,
        activePage: params.activePage,
      },
    },
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "SUCCESS",
    },
  });
};

const HandleModDetail: Handler<Params<"MOD_XRAY">> = async (controller, params) => {
  HandleModXrayDetail(controller, params);
};

const HandleUpdateStatus: Handler<Params<"UPDATE_STATUS">> = async (controller, params) => {
  const state = controller.getState();

  const btnKey = `${params.card}_${params.actionType}${
    typeof params.index === "undefined" ? "" : `_${params.index}`
  }`;
  const btnAction = params.btnAction || btnKey;

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [btnAction]: "LOADING" },
  });

  if ([REPORT_ACTION_STATUS.APPROVE, REPORT_ACTION_STATUS.UNAPPROVE].includes(params.actionType)) {
    const { userId } = await controller.handleEvent({
      message: "CheckUserCredentials",
      params: { btnKey: btnAction, cardKey: params.card, password: params.orderItems[0].password },
    });

    if (!userId) {
      return;
    }
  }

  const promiseArr = params.orderItems.map((item) =>
    [
      REPORT_ACTION_STATUS.EDIT,
      REPORT_ACTION_STATUS.REPORT,
      REPORT_ACTION_STATUS.APPROVE,
      REPORT_ACTION_STATUS.UNAPPROVE,
    ].includes(params.actionType)
      ? CreateUpdateImagingResult(controller, {
          data: {
            username: controller.data.userProfile?.username,
            password: item.password,
          },
          actionType: params.actionType as ReportActionStatusType,
          orderItemId: item.id,
        })
      : UpdateOrderItemAction(controller, {
          data: item,
          actionType: params.actionType as ActionStatusType,
        })
  );

  const response = await Promise.all(promiseArr);

  const isError = response.some((res) => res[1]);

  if (isError) {
    const isLocked = response.some((res) => res[1].__error_type__ === "BILLING_LOCKED");

    SetErrorMessage(controller, {
      ...params,
      btnAction,
      btnError: null,
      ...(isLocked
        ? { error: response.map((item) => item[1]), errorKey: "ModLockExpense" }
        : { card: params.btnAction ? "" : params.card, error: response[0][1] }),
    });
  } else {
    // * actionType นอกจากนี้ ใช้การ refresh จาก websocket
    if ([ACTION_STATUS.READY_TO_VIEW, ACTION_STATUS.UNREADY_TO_VIEW].includes(params.actionType)) {
      const items = response.map((item) => item[0]);

      UpdateImagingOrder(
        controller,
        items.map((item) => ({
          id: item.order_item,
          data: {
            status: item.status,
            ...("radiologist" in item && {
              radiologist_id: item.radiologist,
              radiologist: item.radiologist_name,
              ready_to_view: item.ready_to_view,
            }),
          },
        }))
      );
    }

    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [btnAction]: null },
    });

    params.onSuccess?.();
  }
};

const UpdateImagingOrder: Handler<UpdateOrderType | UpdateOrderType[]> = (controller, params) => {
  const state = controller.getState();

  let orderList = state.ImagingWorkListSequence?.orderQueue?.items || [];

  const updates = Array.isArray(params) ? params : [params];

  orderList = orderList.map((item) => {
    const update = updates.find((acc) => acc.id === item.id);

    return !!update && item.id === update.id
      ? {
          ...item,
          ...update.data,
          ...(update.key ? { [update.key]: update.value } : {}),
        }
      : item;
  });

  controller.setState({
    ImagingWorkListSequence: {
      ...state.ImagingWorkListSequence,
      orderQueue: {
        ...state.ImagingWorkListSequence?.orderQueue,
        items: [...orderList],
      },
    },
  });
};

const HandleGetAuditLog: Handler<Params<"GET_AUDIT_LOG">> = async (controller, params) => {
  const list = await GetAuditLogList(controller, params);

  const state = controller.getState();

  controller.setState({
    ImagingWorkListSequence: {
      ...state.ImagingWorkListSequence,
      auditLogList: list,
    },
  });
};

const HandleOpenPacs: Handler<Params<"OPEN_PACS">> = async (controller, params) => {
  // #const state = controller.getState();

  OpenPacsViewer(controller, params);
  // #controller.setState({
  //   ImagingWorkListSequence: {
  //     ...state.ImagingWorkListSequence,
  //     pacsGalleryDetail: detail,
  //   },
  // });
};

const handleGotoEncounter: Handler<Params<"GO_TO_ENCOUNTER">> = async (controller, params) => {
  const { menu, item } = params;

  // ไปที่ imaging result search
  if (menu === "Imaging Result") {
    await controller.setState({
      imagingResultEditData: item,
      selectedRecordViewIndex: -1,
    });
  } else if (menu === "Imaging Order") {
    await controller.setState({
      imagingOrderEditId: item.order,
      selectedRecordViewIndex: -1,
    });
  }

  const [encounter] = await EncounterDetail.retrieve({
    pk: item.encounter_id,
    apiToken: controller.apiToken,
  });

  controller.handleEvent({
    message: "SelectEncounter" as any,
    params: {
      encounter: { ...encounter, is_imaging_order: true },
      goToMenu: menu,
    },
  });

  params.forward?.();
};

/* ------------------------------------------------------ */

/*                           API                          */

/* ------------------------------------------------------ */
const GetImagingQueueList: Handler<{ activePage: number }> = (controller, params) => {
  const state = controller.getState();

  const filter = state.ImagingWorkListSequence?.filter;
  const offset = (params.activePage - 1) * WORK_LIST_LIMIT;

  const formatParams = (key: keyof FilterType) => {
    return filter?.[key] || undefined;
  };

  return ImagingQueueList.list({
    apiToken: controller.apiToken,
    params: {
      for_radiologist: false,
      stat_priority: true,
      xray_group: formatParams("xray_group"),
      division: formatParams("division"),
      offset: offset,
      status: formatParams("orderStatus"),
      radiologist: formatParams("radiologist"),
      billing_status: filter?.billingStatus ? filter.billingStatus.toLowerCase() : undefined,
      encounter_type: filter?.encounterType ? filter.encounterType.toLowerCase() : undefined,
      patient: formatParams("patient"),
      order_date_start: filter?.startDate
        ? beToAd(filter.startDate)?.format("YYYY-MM-DD")
        : undefined,
      order_date_end: filter?.endDate ? beToAd(filter.endDate)?.format("YYYY-MM-DD") : undefined,
      limit: WORK_LIST_LIMIT,
      exclude_cancel:
        !!filter?.orderStatus && ORDER_ITEM_STATUS_LABEL[filter?.orderStatus] === "CANCELED"
          ? undefined
          : true,
      ...(!formatParams("orderStatus") && { get_reported: true }),
    },
  });
};

/* ------------------------------------------------------ */

/*                          Utils                         */

/* ------------------------------------------------------ */

export const checkReadyToView = (status: OrderStatusType = "", payment: BillingStatusType) => {
  return (
    !status ||
    (!!status &&
      ["REQUESTED", "REGISTERED", "EXECUTED"].includes(ORDER_STATUS[status]) &&
      payment !== BILLING_STATUS.PAID)
  );
};
